import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// indication accepts one of "narcolepsy", "ih" or "agnostic" and determines before gradient
const GradientBeforeCallout = ({
  text,
  indication,
  bgColour,
  heading,
  addedStyles,
  id = "",
}) => {
  const bg = bgColour === "purple" ? tw`gradient35` : tw`bg-white`

  const beforeGradient =
    indication === "narcolepsy"
      ? tw`before:gradient44`
      : indication === "ih"
      ? tw`before:gradient40`
      : indication === "ih-resources"
      ? tw`before:(gradient40-reverse rounded)`
      : indication === "agnostic"
      ? tw`before:gradientAngledReverse`
      : null

  return (
    <div
      id={id}
      css={[
        bg,
        tw`py-10 pl-[45px] pr-[25px] rounded-br-[40px] xl:(py-[30px] pl-[60px] pr-[45px] rounded-br-[60px])`,
        addedStyles && addedStyles,
      ]}
    >
      <div
        css={[
          beforeGradient,
          tw`relative before:(absolute -left-5 h-full w-[5px] rotate-180) xl:(text-4xl before:(left-[-27px]))`,
          indication === "ih-resources" && tw`md:(before:(-top-5 h-[140%]))`,
          indication === "agnostic" && tw`before:(w-[2px])`,
        ]}
      >
        {heading && <h3 tw="font-semibold mb-2.5">{heading}</h3>}
        <div css={[indication === "ih-resources" && tw`lg:(text-[18px])`]}>
          {text}
        </div>
      </div>
    </div>
  )
}

export default GradientBeforeCallout

GradientBeforeCallout.propTypes = {
  // text can be a plain string, or an object if the markup includes links, superscripts
  text: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  // bgColour: PropTypes.string.isRequired,  // commenting out because some pages are missing this
  // gradient: PropTypes.string.isRequired,  // commenting out because some pages are missing this
  // heading: PropTypes.string,
  addedStyles: PropTypes.object,
}

GradientBeforeCallout.defaultProps = {
  // heading: null,
  addedStyles: null,
}
