import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Table = ({ data }) => {
  const tableTheme = {
    background:
      data.theme === "narcolepsy"
        ? tw`tableBGNarco`
        : data.theme === "ih"
        ? tw`lavender-gold-table-gradient-ih`
        : tw`bg-transparent`,
    minWidthMobile: data.mobileHorizontalScroll
      ? tw`sm:(min-w-[700px])`
      : tw`sm:(min-w-[100%])`,
  }

  return (
    <div>
      <div
        css={[
          tableTheme.minWidthMobile,
          data.theme === "narcolepsy" && tw`relative pb-[30px] md:pb-0`,
        ]}
      >
        <table
          css={[
            tw`w-full text-black text-xs text-left rounded-t-[30px] xl:(text-base rounded-t-[45px])`,
            tableTheme.background,
          ]}
          className={data.isBorderPresent ? "table-border-collapsed" : ""}
        >
          <thead
            tw="text-white"
            css={[tw`text-base font-extrabold xl:text-2xl`]}
          >
            {data.header || null}
          </thead>
          <tbody tw="border-alto border-l-[1px] border-r-[1px]">
            {data.body || null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table

Table.propTypes = {
  data: PropTypes.object.isRequired,
}
